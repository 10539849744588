import { Controller } from '@hotwired/stimulus';
import { toDataURL } from 'qrcode';

export default class extends Controller {
  connect() {
    this.printBtns.forEach((btn) => btn.addEventListener('click', this.print));
    this.generateAllQRCodes();
  }

  disconnect() {
    this.printBtns.forEach((btn) => btn.removeEventListener('click', this.print));
  }

  print = (e: Event) => {
    e.preventDefault();

    const clickedButton = e.target;
    if (clickedButton instanceof HTMLElement && !clickedButton.classList.contains('disabled')) {
      const { hashid, type } = clickedButton.dataset;
      if (hashid) {
        const divs = Array.from(this.findAllDivsByHashid(hashid, type)) as HTMLElement[];
        const printWrapper = this.getPrintWrapper();
        if (printWrapper) {
          this.addDivsToWrapper(divs, printWrapper);

          window.print();
        }
      }
    }
  };

  findAllDivsByHashid = (hashid: String, type: any) => {
    if (type === 'me') {
      return document.querySelectorAll(`.wrapper div[id$="-${hashid}"]`);
    } if (type === 'grade') {
      return document.querySelectorAll(`.wrapper div[id^="${hashid}-"]`);
    } if (type === 'class') {
      return document.querySelectorAll(`.wrapper div[id*="-${hashid}-"]`);
    }
    return [];
  };

  getPrintWrapper = (): HTMLElement | null => document.querySelector('.print-wrapper');

  addDivsToWrapper = (divs: HTMLElement[], wrapper: HTMLElement) => {
    /* eslint-disable no-param-reassign */
    wrapper.innerHTML = '';
    divs.forEach((div) => {
      wrapper.appendChild(div.cloneNode(true));
    });
  };

  generateQRCode = async (url: string, imgElm: HTMLImageElement) => {
    imgElm.src = await toDataURL(url, {
      margin: 0,
      width: 128,
      height: 128,
    });
  };

  generateAllQRCodes = async () => {
    this.qrCodeElms.forEach(async (elm) => {
      /* eslint-disable no-param-reassign */
      elm.innerHTML = '';
      const { url } = elm.dataset;
      if (url) {
        const imgElm = document.createElement('img');
        elm.appendChild(imgElm);
        await this.generateQRCode(url, imgElm);
      }
    });
  };

  /* eslint-disable class-methods-use-this */
  get printBtns(): HTMLButtonElement[] {
    return Array.from(document.querySelectorAll('.print-button')) as HTMLButtonElement[];
  }

  /* eslint-disable class-methods-use-this */
  get qrCodeElms(): HTMLElement[] {
    return Array.from(document.querySelectorAll('.wrapper .qrcode-elm')) as HTMLElement[];
  }
}
